/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */

// This variable is set in docker-compose.yml and replaces the default dev
// environment API host
const DOCKER_API_HOST = process.env.REACT_APP_DOCKER_API_HOST;

const API = {};
API.host = DOCKER_API_HOST || process.env.REACT_APP_API_HOST;

const AUTH_API = {};
AUTH_API.host = DOCKER_API_HOST || process.env.REACT_APP_API_HOST;
AUTH_API.tokenPrefix = process.env.REACT_APP_AUTH_API_TOKEN_PREFIX;
AUTH_API.tokenName = process.env.REACT_APP_AUTH_API_TOKEN_NAME;

AUTH_API.facebook = {};
AUTH_API.facebook.clientId = process.env.REACT_APP_AUTH_API_FACEBOOK_CLIENT_ID;

AUTH_API.linkedin = {};
AUTH_API.linkedin.clientId = process.env.REACT_APP_AUTH_API_LINKED_IN_CLIENT_ID;

AUTH_API.oauthConfigurations = {
  google: {
    endpoint: process.env.REACT_APP_AUTH_API_OAUTH_GOOGLE
  }
};

const CACHE_KEYS = {
  autoSuggest: process.env.REACT_APP_CACHE_KEY_AUTH_SUGGEST,
  autoSuggestSelection: process.env.REACT_APP_CACHE_KEY_AUTO_SUGGEST_SELECTION,
  suggestionContext: process.env.REACT_APP_CACHE_KEY_SUGGESTION_CONTEXT,
  searchQuery: process.env.REACT_APP_CACHE_KEY_SEARCH_QUERY,
  userGeolocation: process.env.REACT_APP_CACHE_KEY_USER_LOCATION,
  userLocation: process.env.REACT_APP_CACHE_KEY_USER_LOCATION,
  userTimezone: process.env.REACT_APP_CACHE_KEY_USER_TIMEZONE,
  knockProfile: process.env.REACT_APP_CACHE_KEY_KNOCK_PROFILE,
  refreshToken: process.env.REACT_APP_CACHE_KEY_REFRESH_TOKEN,
  landingPlanSelection: process.env.REACT_APP_CACHE_KEY_LANDING_PLAN_SELECTION,
  redirectLocation: process.env.REACT_APP_CACHE_KEY_REDIRECT_LOCATION,
  includeEveryoneToggle:
    process.env.REACT_APP_CACHE_KEY_INCLUDE_EVERYONE_TOGGLE,
  managerOnboardingSlide:
    process.env.REACT_APP_CACHE_KEY_MANAGER_ONBOARDING_SLIDE,
  loginRedirectPath: process.env.REACT_APP_CACHE_KEY_LOGIN_REDIRECT_PATH,
  syncToHotpads: process.env.REACT_APP_CACHE_KEY_SYNC_TO_HOTPADS,
  altIds: process.env.REACT_APP_CACHE_KEY_ALT_IDS
};

const LOGIN_UI_BASE_URL = process.env.REACT_APP_LOGIN_UI_BASE_URL;
const WEBFRONT_BASE_URL = process.env.REACT_APP_WEBFRONT_BASE_URL;
const REALPAGE_LOGIN_URL = process.env.REACT_APP_REALPAGE_LOGIN_URL;
const AUTH_REDIRECT_URL = process.env.REACT_APP_AUTH_REDIRECT_URL;
const AUTH_CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID;
const UNIFIED_API_URL = process.env.REACT_APP_UNIFIED_API_URL;
const UNIFIED_SCOPE = process.env.REACT_APP_UNIFIED_SCOPE;
const CLAW_BASE_URL = process.env.REACT_APP_CLAW_WEBFRONT_BASE_URL;

const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;

const GLOBAL_ACCESS_PRIVILEGES = {
  login: process.env.REACT_APP_GLOBAL_ACCESS_PRIVILEGES_LOGIN === 'TRUE',
  signup: process.env.REACT_APP_GLOBAL_ACCESS_PRIVILEGES_SIGNUP === 'TRUE'
};

const DEFAULT_PROFILE_IMAGE_URL =
  process.env.REACT_APP_DEFAULT_PROFILE_IMAGE_URL;
const IMAGE_BUCKET = process.env.REACT_APP_IMAGE_BUCKET;
const VIDEO_BUCKET = process.env.REACT_APP_VIDEO_BUCKET;
const MOBILE_DOWNLOAD_LINKS = {
  iosRenter: process.env.REACT_APP_MOBILE_DOWNLOAD_LINKS_IOS_RENTER,
  iosManager: process.env.REACT_APP_MOBILE_DOWNLOAD_LINKS_IOS_MANAGER
};

const INTEGRATION_SNIPPET_SETTINGS = {
  jsPath: process.env.REACT_APP_INTEGRATION_SNIPPET_SETTINGS_JS_PATH,
  cssPath: process.env.REACT_APP_INTEGRATION_SNIPPET_SETTINGS_CSS_PATH
};

const FIREBASE = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const FIRBASE_APP_VAPIDKEY = process.env.REACT_APP_FIREBASE_APP_VAPIDKEY;

const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === 'TRUE';
const ANALYTICS_BASE_URL = process.env.REACT_APP_ANALYTICS_BASE_URL;
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const INBOUND_BROWSER_CALLING_BASE_URL =
  process.env.REACT_APP_INBOUND_BROWSER_CALLING_BASE_URL;

const LAUNCHDARKLY_CLIENTSIDE_ID =
  process.env.REACT_APP_LAUNCHDARKLY_CLIENTSIDE_ID;

const REACT_APP_HEAP_ANALYTICS_APP_ID =
  process.env.REACT_APP_HEAP_ANALYTICS_APP_ID;

const REACT_APP_GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

const UIS = {
  token: process.env.REACT_APP_UIS_TOKEN,
  host: process.env.REACT_APP_UIS_HOST
};

const REACT_APP_SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;
const REACT_APP_TRAINING_URL = process.env.REACT_APP_TRAINING_URL;

const ANALYTICS_GRAPHQL_API = process.env.REACT_APP_ANALYTICS_GRAPHQL_API;

const REACT_APP_UNIFIED_LOGIN_URL = process.env.REACT_APP_UNIFIED_LOGIN_URL;

const REACT_APP_ONSITE_HOST = process.env.REACT_APP_ONSITE_HOST;

const REACT_APP_DATADOG_APPLICATION_ID =
  process.env.REACT_APP_DATADOG_APPLICATION_ID;
const REACT_APP_DATADOG_CLIENT_TOKEN =
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const REACT_APP_DATADOG_ENVIRONMENT = process.env.REACT_APP_DATADOG_ENVIRONMENT;

const REACT_APP_DEMANDX_API_BASE_URL =
  process.env.REACT_APP_DEMANDX_API_BASE_URL;
const REACT_APP_DEMANDX_HOT_LEAD_SCORE_THRESHOLD =
  process.env.REACT_APP_DEMANDX_HOT_LEAD_SCORE_THRESHOLD;
const REACT_APP_DEMANDX_HOT_LEAD_PRIORITY_THRESHOLD =
  process.env.REACT_APP_DEMANDX_HOT_LEAD_PRIORITY_THRESHOLD;

const REACT_APP_GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const REACT_APP_RELEASE_NOTES_URL = process.env.REACT_APP_RELEASE_NOTES_URL;

const REACT_APP_RP_SALESFORCE_URL = process.env.REACT_APP_RP_SALESFORCE_URL;
const REACT_APP_RP_SALESFORCE_SITE = process.env.REACT_APP_RP_SALESFORCE_SITE;
const REACT_APP_RP_SALESFORCE_ID = process.env.REACT_APP_RP_SALESFORCE_ID;
const REACT_APP_RP_SALESFORCE_LIVE_AGENT_CONTENT_URL =
  process.env.REACT_APP_RP_SALESFORCE_LIVE_AGENT_CONTENT_URL;
const REACT_APP_RP_SALESFORCE_DEPLOYMENT_ID =
  process.env.REACT_APP_RP_SALESFORCE_DEPLOYMENT_ID;
const REACT_APP_RP_SALESFORCE_BUTTON_ID =
  process.env.REACT_APP_RP_SALESFORCE_BUTTON_ID;
const REACT_APP_RP_SALESFORCE_LIVE_AGENT_URL =
  process.env.REACT_APP_RP_SALESFORCE_LIVE_AGENT_URL;
const REACT_APP_RP_SALESFORCE_SRC = process.env.REACT_APP_RP_SALESFORCE_SRC;

const REACT_APP_WEBFRONT_BASE_URL = process.env.REACT_APP_WEBFRONT_BASE_URL;

const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_URL;
const REACT_APP_UNIFIED_QUOTE_WIDGET =
  process.env.REACT_APP_UNIFIED_QUOTE_WIDGET;

const REACT_APP_REALPAGE_DEFAULT_DOMAIN =
  process.env.REACT_APP_REALPAGE_DEFAULT_DOMAIN;

export const APP_CONFIG = {
  API,
  AUTH_API,
  CACHE_KEYS,
  PUSHER_KEY,
  GLOBAL_ACCESS_PRIVILEGES,
  DEFAULT_PROFILE_IMAGE_URL,
  MOBILE_DOWNLOAD_LINKS,
  ENVIRONMENT,
  IMAGE_BUCKET,
  VIDEO_BUCKET,
  MAINTENANCE_MODE,
  INTEGRATION_SNIPPET_SETTINGS,
  ANALYTICS_BASE_URL,
  INBOUND_BROWSER_CALLING_BASE_URL,
  FIREBASE,
  LOGIN_UI_BASE_URL,
  WEBFRONT_BASE_URL,
  REALPAGE_LOGIN_URL,
  CLAW_BASE_URL,
  LAUNCHDARKLY_CLIENTSIDE_ID,
  REACT_APP_HEAP_ANALYTICS_APP_ID,
  UIS,
  REACT_APP_SUPPORT_URL,
  REACT_APP_TRAINING_URL,
  ANALYTICS_GRAPHQL_API,
  REACT_APP_ONSITE_HOST,
  REACT_APP_DATADOG_APPLICATION_ID,
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_ENVIRONMENT,
  REACT_APP_UNIFIED_LOGIN_URL,
  AUTH_REDIRECT_URL,
  AUTH_CLIENT_ID,
  UNIFIED_API_URL,
  UNIFIED_SCOPE,
  REACT_APP_DEMANDX_API_BASE_URL,
  REACT_APP_DEMANDX_HOT_LEAD_SCORE_THRESHOLD,
  REACT_APP_DEMANDX_HOT_LEAD_PRIORITY_THRESHOLD,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_RELEASE_NOTES_URL,
  REACT_APP_RP_SALESFORCE_URL,
  REACT_APP_RP_SALESFORCE_SITE,
  REACT_APP_RP_SALESFORCE_ID,
  REACT_APP_RP_SALESFORCE_LIVE_AGENT_CONTENT_URL,
  REACT_APP_RP_SALESFORCE_DEPLOYMENT_ID,
  REACT_APP_RP_SALESFORCE_BUTTON_ID,
  REACT_APP_RP_SALESFORCE_LIVE_AGENT_URL,
  REACT_APP_RP_SALESFORCE_SRC,
  REACT_APP_WEBFRONT_BASE_URL,
  ADMIN_BASE_URL,
  REACT_APP_UNIFIED_QUOTE_WIDGET,
  REACT_APP_REALPAGE_DEFAULT_DOMAIN,
  FIRBASE_APP_VAPIDKEY
};
