import React, { FC } from 'react';

import { ExternalLinkIcon } from './icons';
import { Link, makeStyles } from '@material-ui/core';

import { useOneSiteResidentPageUrl } from 'app/services/residents/hooks';

interface ViewResidentInOneSiteBodyProps {
  residentId: number;
}

const useStyles = makeStyles((theme) => ({
  viewResidentInOneSiteContainer: {
    marginBottom: '-8px'
  },
  viewResidentInOneSiteLink: {
    display: 'flex',
    alignItems: 'left',
    fontSize: '14px'
  },
  viewResidentInOneSiteLinkIcon: {
    verticalAlign: 'middle',
    marginRight: '8px'
  }
}));

const ViewResidentInOneSiteBody: FC<ViewResidentInOneSiteBodyProps> = ({
  residentId
}) => {
  const { url } = useOneSiteResidentPageUrl(residentId);
  const classes = useStyles();

  if (!url) {
    return null;
  }

  return (
    <div className={classes.viewResidentInOneSiteContainer}>
      <Link
        href={url}
        target="_blank"
        className={classes.viewResidentInOneSiteLink}
      >
        <ExternalLinkIcon
          height={'16'}
          width={'16'}
          className={classes.viewResidentInOneSiteLinkIcon}
        />
        <div>Open Resident Profile in OneSite</div>
      </Link>
    </div>
  );
};

export default ViewResidentInOneSiteBody;
