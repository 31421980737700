/* eslint-disable */
/* TODO: If you edit this file remove eslint-disable and fix linting errors in this file. */
import { getMessaging, getToken, deleteToken } from 'firebase/messaging';

(function (angular) {
  'use strict';

  var app = angular.module('knockApp');

  const browserNotificationsService = function (
    $timeout,
    $q,
    appDataService,
    notificationsApi,
    localStorageService,
    apiBase,
    firebaseAppVapidkey
  ) {
    var self = this;

    try {
      self._messaging = getMessaging();
    } catch (e) {
      console.warn('This browser does not support the Push API', e);
    }

    self._justPlayedSound = false;
    self._tokenKey = 'notifications_token';
    self._vapidKey = firebaseAppVapidkey;

    const desktop_notification = localStorage.getItem(
      'DesktopNotificationEnabled'
    );
    if (desktop_notification == true) {
      if (self._messaging != null) {
        getToken(self._messaging, { vapidKey: self._vapidKey })
          .then(function (refreshedToken) {
            self._sendTokenToServer(refreshedToken);
          })
          .catch(function (err) {
            console.error('Unable to retrieve refreshed token', err);
          });
      }
    }

    self.registerServiceWorker = function () {
      return navigator.serviceWorker
        .register('/firebase-messaging-sw.js', {
          scope: '/firebase-cloud-messaging-push-scope'
        })
        .then(function (registration) {
          console.log('Service worker successfully registered.');
          return registration;
        })
        .catch(function (err) {
          console.error('Unable to register service worker.', err);
        });
    };

    self.setUpSWMessageListener = function (registration) {
      navigator.serviceWorker.addEventListener('message', function (event) {
        var payload = event.data['data'];
        console.log(
          '[firebase-messaging-sw.js] Received foreground message ',
          payload
        );
        var body = payload.body;
        var title = payload.title;
        var tag = payload.tag;
        var conversationUrl = '/conversation/' + tag;

        console.log('Notification title - ', title);
        var options = {
          body: body,
          tag: tag,
          requireInteraction: true,
          icon: '/images/icon.png'
        };
        registration.showNotification(title, options);
      });
    };

    if (navigator.serviceWorker) {
      // Unregister firebase inactive existing service workers
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          registrations.forEach(function (registration) {
            // Check if the service worker is inactive and matches the specific file name
            if (
              registration.active === null &&
              registration.scriptURL.includes('firebase-messaging-sw.js')
            ) {
              registration.unregister().then(function (success) {
                if (success) {
                  console.log(
                    'Inactive Service Worker unregistered:',
                    registration
                  );
                }
              });
            }
          });
        })
        .then(function () {
          self.registerServiceWorker().then(function (registration) {
            self.setUpSWMessageListener(registration);
          });
        });
    }

    self.toggleNotifications = function (active) {
      return Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(self._messaging, { vapidKey: self._vapidKey })
            .then((currentToken) => {
              if (currentToken) {
                self._storeToken(currentToken);
                if (!active) {
                  self._sendTokenToServer(currentToken);
                } else {
                  self.deleteNotificationsToken(currentToken);
                }
              } else {
                console.log('Token not available');
              }
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
    };

    self._sendTokenToServer = function (currentToken) {
      self._storeToken(currentToken);

      self._registerDevice().then(
        function () {},
        function (error) {
          self._removeStoredToken();
          console.log(
            'Error registering device token: ' +
              currentToken +
              ' | ' +
              JSON.stringify(error)
          );
        }
      );
    };

    self.deleteNotificationsToken = function (currentToken) {
      var deferred = $q.defer();
      var token = currentToken || self._getStoredToken();
      if (!token) {
        deferred.resolve();
        return deferred.promise;
      }

      return deleteToken(self._messaging, token)
        .then(() => {
          self._removeStoredToken();
          deferred.resolve();
        })
        .catch((err) => {
          console.log('An error occurred while deleting the token. ', err);
          deferred.reject(err);
        });
    };

    self.toggleAudioNotifications = function () {
      appDataService.data.preferences.audioNotificationsEnabled =
        !appDataService.data.preferences.audioNotificationsEnabled;

      return notificationsApi.updatePreferences(null, null, {
        'manager-audio-notifications-enabled':
          appDataService.data.preferences.audioNotificationsEnabled
      });
    };

    self._registerDevice = function () {
      var token = self._getStoredToken();

      if (!token) {
        throw new Error('no firebase token stored!');
      }

      return apiBase.post('/user/notifications/device', {
        device_registration_id: token
      });
    };

    self.getTokenSentToServer = function () {
      return localStorageService.get('notifications_token');
    };

    self._getStoredToken = function () {
      return localStorageService.get(self._tokenKey);
    };

    self._storeToken = function (token) {
      localStorageService.set(self._tokenKey, token);
    };

    self._removeStoredToken = function () {
      localStorageService.remove(self._tokenKey);
    };

    self.playSound = function (notificationType) {
      if (
        self._justPlayedSound ||
        !appDataService.data.preferences.audioNotificationsEnabled
      ) {
        return;
      }

      var audioFile =
        notificationType === 'message'
          ? '/media/notification.mp3'
          : '/media/phone_call.mp3';
      var audioBlock = document.getElementById('notificationAudio');

      self._justPlayedSound = true;

      audioBlock.innerHTML =
        '' +
        '<audio autoplay="autoplay">' +
        '<source src="' +
        audioFile +
        '" type="audio/mpeg" />' +
        '<embed hidden="true" autostart="true" loop="false" src="' +
        audioFile +
        '" />' +
        '</audio>';

      $timeout(function () {
        self._justPlayedSound = false;
      }, 8000);
    };
    return self;
  };

  browserNotificationsService.$inject = [
    '$timeout',
    '$q',
    'appDataService',
    'notificationsApi',
    'localStorageService',
    'apiBase',
    'firebaseAppVapidkey'
  ];
  app.factory('browserNotificationsService', browserNotificationsService);
})(window.angular);
