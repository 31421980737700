import { initializeApp } from 'firebase/app';

export default function runInitializeFirebase(app: any) {
  app.run([
    'firebaseConfig',
    function (firebaseConfig: any) {
      initializeApp(firebaseConfig);
    }
  ]);
}
