import findLast from 'lodash/findLast';
import map from 'lodash/map';
import endsWith from 'lodash/endsWith';
import remove from 'lodash/remove';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import styles from '../../styles/conversations/_conversationTextArea.scss';
import { getEmptyProperty } from '../../../utilities/propertyUtilities';
import { STORAGE_CONVERSATION_MESSAGE_MODE_KEY } from '../../../constants/constants';
import {
  getNbaHotLead,
  getNextBestActionScoreByProspect
} from 'LegacyAngularApp/legacy-angular-app/services/demandXService';
import moment from 'moment';

(function (angular, $) {
  var app = angular.module('knock-Conversations');

  app.directive('conversationTextArea', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/conversations/conversation-text-area.html',
      controller: 'ConversationTextAreaController',
      scope: {
        prospect: '=?',
        resident: '=?',
        manager: '=?',
        stream: '=',
        messageText: '=',
        messageSubject: '=',
        replyingToMessage: '=',
        onSetReplyingTo: '=',
        onSendMessage: '=',
        onCallPlaced: '=',
        userIsOnline: '=',
        attachments: '=',
        managerCommunityIds: '='
      }
    };
  });

  const ConversationTextAreaController = function (
    $rootScope,
    $routeParams,
    $window,
    $moment,
    $scope,
    $timeout,
    $mdToast,
    $mdDialog,
    $location,
    $auth,
    prospectAppointmentModalFactory,
    userInteractionsResidentPackageModalService,
    voiceService,
    userService,
    addAttachmentDialogFactory,
    shareSMSOptInModalFactory,
    conversationsService,
    visitsService,
    shownUnitsModalService,
    streamCarouselService,
    appDataService,
    unsubscribeHelper,
    prospectsApi,
    userInteractionsService,
    propertyApi,
    communityApi,
    managerApi,
    quotesService,
    timeService,
    unitApi,
    windowManagementService,
    prospectIntegrationsApi,
    unitQuotesService,
    rentMatrixService,
    ProfileService,
    messagingApi,
    pusherInstanceService
  ) {
    var self = this;
    $scope._isCallInTransient = false;

    var attachmentAddedHandlerDestroy = $rootScope.$on(
      'attachmentAdded',
      function (event, data) {
        if ($scope.stream.id === data.stream_id) {
          let setQuoteTarget = false;
          if (data.attachment && $scope.getMessageMode().target === 'call') {
            setQuoteTarget = $scope.handleQuoteAttachmentDuringCallTab(data);
          }
          if ($scope.getMessageMode().target === 'email' && !setQuoteTarget) {
            $scope.attachments.push(data.attachment);
          } else if (!setQuoteTarget) {
            $scope.openAttachmentDialog([data.attachment]);
          }
        }
      }
    );

    // TODO: remove me when this is ready for the masses | packageFeatureProd
    $scope.enablePackageFeature = $routeParams.internal === 'true';
    $scope.isPlaidIdvEnabled = $rootScope.featureFlags.LMA_PLAID_IDV;
    $scope.isPlaidApiv2Checked = $rootScope.featureFlags.LMA_PLAID_APIV2_CHECK;

    $scope.isCompanyQuickRepliesEnabled =
      $rootScope.featureFlags.COMPANY_QUICK_REPLIES;

    $scope.isLeasingBinderOpen = false;
    $scope.emailDomainBlocklist = $rootScope.emailDomainBlocklist;

    var person = $scope.prospect || $scope.resident;
    $scope.targetName =
      person.profile.first_name + ' ' + person.profile.last_name;
    $scope.targetEmailAddress = person.profile.email;

    $scope.overflowMenuItems = [];
    $scope.showOverflowMenu = false;
    $scope.emojiPickerContext = 'ConversationArea';
    $scope.ccRecipients = [];
    $scope.followUpData = [];
    $scope.showNoteModal = false;
    $scope.showFollowUpModal = false;

    $scope.isDemandXEnabled =
      $rootScope.appPreferences.company.enable_demand_x && $scope.prospect;
    $scope.isBulkCommunicationEnhancementEnabled =
      $rootScope.featureFlags.BULK_COMMUNICATION_ENHANCEMENT;

    $scope.isAgentAttributionEnable = $rootScope.featureFlags.AGENT_ATTRIBUTION;

    $scope.isDemandXDemoEnabled =
      $rootScope.featureFlags.DEMAND_X_DEMO &&
      $rootScope.appPreferences.company.enable_demand_x &&
      $scope.prospect;

    $scope.managerInfo = {};
    $scope.getManagerQuickReplies = () => {
      const id = $scope.prospect
        ? $scope.prospect.property.id
        : $scope.resident.residence.property.id;
      return managerApi.getManagerQuickReplies(id);
    };
    $scope.updateManagerQuickReply = managerApi.updateManagerQuickReply;
    $scope.deleteManagerQuickReply = managerApi.deleteManagerQuickReply;
    $scope.isAvailabilityDrawerEnable =
      $rootScope.featureFlags.ENHANCED_LEASING_BINDER && $scope.prospect;
    $scope.showAvailabilityBackButton = false; // THIS FLAG WILL ENABLE IF USER COMES FROM LEASING BINDER
    $scope.showAvailabilityDrawer = false;
    $scope.getUnitsApi = unitApi.getUnits;
    $scope.getUnitLink = prospectsApi.getProspectsUniqueShortUrl;
    $scope.isConnectedProfilesEnabled =
      $rootScope.appPreferences.company.enable_connected_profiles;
    $scope.hasRealPageIntegration = false;
    $scope.prospectIntegrationsApi = prospectIntegrationsApi;
    $scope.unitQuotesService = unitQuotesService;
    $scope.companyId = appDataService.data.currentCompany.id;
    $scope.vendorProspectId = 0;

    $scope.timeService = timeService;
    $scope.isVoiceAppForOutboundCallsEnabled =
      $rootScope.featureFlags.VOICE_APP_FOR_OUTBOUND_CALLS;
    $scope.isQuoteAttachmentAppend = false;

    $rootScope.$on(
      conversationsService.events.emailUnsubscribeStatusChanged,
      function () {
        self._generateHasEmailOptOut();
      }
    );

    $scope.$on('$destroy', function () {
      attachmentAddedHandlerDestroy();
    });

    $rootScope.$on('prospectSaved', function (event, data) {
      $scope.targetEmailAddress = data;
    });

    $scope.$watch('stream', function (prevStream, newStream) {
      if (prevStream && newStream && prevStream.id !== newStream.id) {
        self._reset();
        self._getMergeTags();
      }
    });

    $scope.$watch(
      'stream.participants',
      () => {
        this._generateHasEmailOptOut();
      },
      // Deep equals
      true
    );

    $scope.$watch('userIsOnline', function () {
      if ($scope.userIsOnline) {
        $scope.data.userWasOnline = true;
      }
    });

    $scope.$watch('prospect', function () {
      if ($scope.prospect) {
        $scope.targetName =
          $scope.prospect.profile.first_name +
          ' ' +
          $scope.prospect.profile.last_name;
        $scope.targetEmailAddress = $scope.prospect.profile.email;
        $scope.ccRecipients = [];

        $scope.prospect.consentOverriden = false;
        const teamMembers = self._getTeamMembers();
        $scope.ownerInfo = teamMembers.find(
          (member) => member.id === $scope.prospect?.assigned_manager_id
        );

        self._setupConsentOverrideCutoff();
        self._getNBA();
      }
    });

    $scope.$watch('resident', function (nextResident, prevResident) {
      if (nextResident && prevResident && nextResident.id !== prevResident.id) {
        $scope.targetName =
          nextResident.profile.first_name +
          ' ' +
          nextResident.profile.last_name;
        $scope.targetEmailAddress = nextResident.profile.email;
        const teamMembers = self._getTeamMembers();
        $scope.ownerInfo = teamMembers.find(
          (member) => member.id === nextResident?.added_by
        );
        $scope.ccRecipients = [];
      }
    });

    $scope.$watch('data.messageModeIndex', function () {
      // We need to broadcast the change to the current tab in order for the new emoji picker button to be rendered properly
      $rootScope.$emit('messageMode', $scope.getMessageMode().target);

      // Since the overflow menu may hold different things based on the current tab, we need to set it here even if the window size hasn't
      // changed
      self._setOverflowMenuItems();
    });

    self._setupConsentOverrideCutoff = function () {
      self._lastMessageCount = -1;

      // After the below, hard-coded date, we no longer display the SMS consent checkbox for legal reasons
      self._prospectCreatedMoment = $moment(
        ($scope.prospect || $scope.resident).created_time
      );
      self._consentCutoffMoment = $moment('6/20/2018').startOf('day');
    };

    $scope.scaleTextScroll = function () {
      $timeout(function () {
        var msgWrap = angular.element('.conversation-messages')[0];
        var textArea = angular.element('.conversation-text-area-body');

        var msgWrapScroll = msgWrap.scrollHeight - msgWrap.clientHeight;

        $scope.data.msgAreaScrolled = msgWrapScroll >= msgWrap.scrollTop + 100;

        if (
          $scope.data.textAreaHeight !== null &&
          $scope.data.textAreaHeight !== textArea.height()
        ) {
          if (!$scope.data.msgAreaScrolled) {
            msgWrap.scrollTop = msgWrapScroll;
          }
        }

        $scope.data.textAreaHeight = textArea.height();
      }, 100);
    };

    $scope.$watch('replyingToMessage', function () {
      if ($scope.replyingToMessage) {
        $scope.data.subject.isEditing = false;
        $scope.data.messageModeIndex = 2;
      }
    });

    $scope.$watch('messageText', function (prevText, nextText) {
      var target = $scope.getMessageMode().target;

      if ($scope.messageText === null) {
        return;
      }

      if (
        prevText !== nextText &&
        $scope.messageText.length >= 0 &&
        ($scope.prospect || $scope.resident)
      ) {
        var userTypeAndId = self._getOtherUserTypeAndId();

        conversationsService.storeDraft(
          userTypeAndId.userType,
          userTypeAndId.userId,
          target,
          $scope.messageText
        );
      }

      $scope.scaleTextScroll();
    });

    $scope.messageModes = {
      call: {
        target: 'call',
        placeholderText: '',
        nbaTooltip: 'We suggest a call',
        tooltipClass: 'call-tab-overlay',
        maxLength: 0
      },
      sms: {
        target: 'sms',
        placeholderText: 'Type your SMS text here',
        nbaTooltip: 'We suggest an SMS',
        tooltipClass: 'phone-tab-overlay',
        maxLength: 320
      },
      email: {
        target: 'email',
        placeholderText: 'Type your email here',
        nbaTooltip: 'We suggest an email',
        tooltipClass: 'email-tab-overlay',
        maxLength: 10000
      },
      chat: {
        target: 'web',
        placeholderText: 'Type your chat message here',
        maxLength: 150
      },
      facebook: {
        target: 'facebook',
        placeholderText: 'Type your facebook chat message here',
        maxLength: 150
      }
    };

    $scope.enableRichTextEditor =
      $rootScope.appPreferences.company.enable_rich_text_editor;

    $scope.enableBrowserCalling = voiceService._enableBrowserCalling;

    const assignedRelayPhone = ($scope.resident || $scope.prospect)
      .assigned_relay_phone;

    $scope.data = {
      user: userService.getScopedUser(),
      property: appDataService.getProperty($scope.stream.property_id),
      properties: appDataService
        .getProperties()
        .sort((property1, property2) =>
          property1.Property.data.location.name.localeCompare(
            property2.Property.data.location.name
          )
        ),
      communities: [],
      currentCommunity: {},
      propertyIdFromBinder: null,
      messageModeIndex: 0,
      tabIndices: [
        $scope.messageModes.call,
        $scope.messageModes.sms,
        $scope.messageModes.email,
        $scope.messageModes.chat,
        $scope.messageModes.facebook
      ],
      activeCall: null,
      isPhoneCallTransient: false,
      isBrowserCallInTransient: false,
      isVoiceAppStatusInCall: false,
      isVoiceAppCall: false,
      onInboundCall: false,
      isRenterMailer: false,
      showFormatting: null,
      subject: {
        isEditing: false,
        editedText: ''
      },
      userWasOnline: $scope.userIsOnline,
      hasReceivedSMS: false,
      emailOptOutReason: null,
      hasEmailOptOut: false,
      isTyping: false,
      textAreaHeight: null,
      msgAreaScrolled: false,
      newUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      isCallIntelEnabled: $rootScope.featureFlags.CALL_INTEL_STANDALONE,
      isGettingSelfVerifyIdUrl: false,
      disableLiveChat: false,
      isTranscribed: false,
      nbaTooltip: {
        class: '',
        text: ''
      },
      hasNBA: false,
      showWalkinModal: false,
      timeOptions: [],
      useNewUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      teamMembers: [],
      walkinDataSaving: false,
      selectedFloorPlanId: $rootScope.featureFlags.NEW_UNITS
        ? $scope.prospect?.preferences?.preferred_layout_id
        : $scope.prospect?.preferences?.preferred_property_floorplan_id,
      managerId: $scope.prospect?.assigned_manager_id
        ? $scope.prospect.assigned_manager_id
        : null
    };

    $scope.availabilityDrawerProperty = $scope.data.property.Property.data;

    self._initialize = function () {
      self._initDragDropHandlers();
      self._reset();
      self._setupConsentOverrideCutoff();
      self._getMergeTags();
      self._getManagerInfo();
      self._getCommunity($scope.data.property.Property.data.id);
      self._getNBA();
      self._getProfile();
      self._getVoiceAppStatusFromPusher();

      if ($scope.isVoiceAppForOutboundCallsEnabled) {
        self._getClientCallStatus();
      }

      const defaultMessageMode = $window.sessionStorage.getItem(
        STORAGE_CONVERSATION_MESSAGE_MODE_KEY
      );

      if (defaultMessageMode) {
        const tabIndex = findIndex($scope.data.tabIndices, function (mode) {
          return mode.target === defaultMessageMode;
        });

        if (tabIndex > -1) {
          $scope.data.messageModeIndex = tabIndex;
        }

        $window.sessionStorage.removeItem(
          STORAGE_CONVERSATION_MESSAGE_MODE_KEY
        );
      }

      if ($scope.prospect && $scope.prospect.property_id) {
        self._getPropertyAiConfig($scope.prospect.property_id);
      }

      if ($scope.prospect) {
        const index = $scope.prospect.integrations.findIndex(
          (integration) => integration.vendor === 'realpage'
        );

        if (index > -1) {
          $scope.hasRealPageIntegration = true;
          $scope.vendorProspectId =
            $scope.prospect.integrations[index].vendor_prospect_id;
        }
      }

      $scope.hasRealPageIntegration =
        $scope.prospect && $scope.prospect.integrations
          ? $scope.prospect.integrations.some(
              (integration) => integration.vendor === 'realpage'
            )
          : false;
    };
    $scope.handleQuoteAttachmentDuringCallTab = function (
      data,
      isApplyTrigger = false
    ) {
      const user = $scope.prospect || $scope.resident;
      let setQuoteTarget = false;
      if (!$scope.isMissingEmail() && !$scope.data.hasEmailOptOut) {
        setQuoteTarget = true;
        $scope.tabChange('email', data.attachment);
        $scope.data.messageModeIndex = 2;
      } else if (user.sms_consent && user.sms_consent.status === 'granted') {
        setQuoteTarget = true;
        $scope.tabChange('sms', data.attachment);
        $scope.data.messageModeIndex = 1;
        $scope.openAttachmentDialog([data.attachment]);
      }
      if (isApplyTrigger) {
        $scope.$apply();
      }
      return setQuoteTarget;
    };
    const EMAIL_TARGET_TYPES = {
      PROSPECT: 'prospect',
      RESIDENT: 'resident'
    };

    self._getCommunity = function (communityId) {
      return communityApi
        .getCommunity(communityId)
        .success(function (response) {
          $scope.data.currentCommunity = {
            ...response.community,
            tracking_number: assignedRelayPhone
          };
        });
    };

    self._getNextBestActionScoreByProspect = async function () {
      try {
        const nextBestActionScore = await getNextBestActionScoreByProspect({
          pmc_id: appDataService.data.currentCompany.id,
          prospect_id: $scope.prospect.id,
          property_id: $scope.prospect.property_id
        });
        if (
          !getNbaHotLead(
            nextBestActionScore,
            $rootScope.featureFlags.DEMAND_X_PRIORITY
          )
        ) {
          return;
        }
        self._setNbaBestAction(nextBestActionScore?.nba);
      } catch (error) {
        console.error('Error getting next best action:', error.message);
      }
    };
    self._setNbaBestAction = function (nba) {
      let nbaText = {
        tabIndex: undefined
      };

      const callProbability = nba?.phone?.probability || 0;
      const smsProbability = nba?.text?.probability || 0;
      const emailProbability = nba?.email?.probability || 0;
      const waitProbability = nba?.wait?.probability || 0;

      if (
        callProbability > 0 &&
        callProbability > smsProbability &&
        callProbability > emailProbability &&
        callProbability > waitProbability
      ) {
        nbaText = self._getNbaText('call');
      } else if (
        smsProbability > 0 &&
        smsProbability > callProbability &&
        smsProbability > emailProbability &&
        smsProbability > waitProbability
      ) {
        nbaText = self._getNbaText('sms');
      } else if (
        emailProbability > 0 &&
        emailProbability > callProbability &&
        emailProbability > smsProbability &&
        emailProbability > waitProbability
      ) {
        nbaText = self._getNbaText('email');
      }
      if (typeof nbaText.tabIndex !== 'undefined') {
        $scope.$apply(() => {
          $scope.data.hasNBA = true;
          if (!$scope._isCallInTransient) {
            $scope.data.messageModeIndex = nbaText.tabIndex;
            $scope.data.nbaTooltip = nbaText.tooltip;
          }
        });
      }
    };

    self._getNbaText = function (actionType) {
      let tabIndex = undefined;
      let tooltip = {};
      if (!self._isActionDisable(actionType)) {
        tabIndex = $scope.data.tabIndices.findIndex(
          (tab) => tab.target === actionType
        );
        tooltip = {
          class: $scope.messageModes[actionType]?.tooltipClass || '',
          text: $scope.messageModes[actionType]?.nbaTooltip || ''
        };
      }
      return {
        tabIndex,
        tooltip
      };
    };

    self._getNBA = async function () {
      $scope.data.hasNBA = false;
      if ($scope.isDemandXEnabled) {
        await self._getNextBestActionScoreByProspect();
      }

      if (!$scope.data.hasNBA && $scope.isDemandXDemoEnabled) {
        self._setDemandXDemoNBA();
      }
    };

    self._getProfile = async function () {
      const data = await ProfileService.getProfile();
      $scope.data.profile = {
        name: `${data.profile.first_name} ${data.profile.last_name}`,
        id: data.profile.manager_id,
        photo: data.profile.photo
      };
    };

    self._setDemandXDemoNBA = function () {
      if (!$scope.prospect.profile.first_name.toLowerCase().startsWith('k')) {
        return;
      }

      $timeout(function () {
        $scope.$apply(() => {
          if (!$scope._isCallInTransient) {
            $scope.data.messageModeIndex = 2;
            $scope.data.nbaTooltip = {
              class: 'email-tab-overlay',
              text: 'We suggest an email'
            };
          }
        });
      });
    };

    self._isActionDisable = function (actionType) {
      switch (actionType) {
        case 'call':
          return $scope.isMissingPhone() || $scope.isInvalidPhone();
        case 'sms':
          return (
            $scope.isMissingPhone() ||
            $scope.isInvalidPhone() ||
            ($scope.isMissingSMSConsent() && !$scope.hasConsentOverride())
          );
        case 'email':
          return $scope.isMissingEmail() || $scope.data.hasEmailOptOut;
        default:
          return false;
      }
    };

    self._getEmailTargetType = () => {
      if ($scope.prospect) {
        return EMAIL_TARGET_TYPES.PROSPECT;
      }

      if ($scope.resident) {
        return EMAIL_TARGET_TYPES.RESIDENT;
      }
    };

    self._getMergeTags = function () {
      const targetType = self._getEmailTargetType();
      $scope.mergeTags = appDataService.getEmailMergeTags(targetType);
      $scope.quickReplyMergeTags = JSON.parse(JSON.stringify($scope.mergeTags));
      const mergeTagValues = $scope.stream.merge_tag_values || {};

      // populate merge tag values
      $scope.mergeTags.forEach((mergeTag) => {
        mergeTag.value = mergeTagValues[mergeTag.name] || `#${mergeTag.name}`;
      });

      $scope.quickReplyMergeTags.forEach((mergeTag) => {
        // quick reply merge tags should not autopopulate values
        mergeTag.value = `#${mergeTag.name}`;
      });
    };

    self._getManagerInfo = function () {
      const teamMembers = appDataService.getTeamMembers().map((member) => {
        return {
          id: member.Manager.id,
          name:
            member.ManagerInfo.first_name + ' ' + member.ManagerInfo.last_name
        };
      });
      $scope.data.teamMembers = teamMembers;
      $scope.managerInfo = teamMembers.find(
        (member) => member.id === userService.getScopedUser().id
      );
    };

    self._getManagerEmailTags = async function (
      userId,
      updateLoggedinUserMergeTags = false
    ) {
      try {
        const { data } = await messagingApi.getEmailMergeTags(
          userId,
          $scope.stream.id
        );

        if (
          updateLoggedinUserMergeTags &&
          data.merge_tag_values?.ManagerFirstName &&
          $scope.stream?.merge_tag_values?.ManagerFirstName
        ) {
          $scope.stream.merge_tag_values.ManagerFirstName =
            data.merge_tag_values?.ManagerFirstName ||
            $scope.stream.merge_tag_values.ManagerFirstName;
          $scope.stream.merge_tag_values.ManagerLastName =
            data.merge_tag_values?.ManagerLastName ||
            $scope.stream.merge_tag_values.ManagerLastName;
        }
        for (const mergeTagKey in data.merge_tag_values) {
          const mergeIndex = $scope.mergeTags.findIndex(
            (mergeValue) => mergeValue.name === mergeTagKey
          );
          if (mergeIndex > -1) {
            $scope.mergeTags[mergeIndex].value =
              data.merge_tag_values[mergeTagKey] ||
              $scope.mergeTags[mergeIndex].value;
          }
        }

        $scope.quickReplyMergeTags = JSON.parse(
          JSON.stringify($scope.mergeTags)
        );

        $scope.quickReplyMergeTags.forEach((mergeTag) => {
          mergeTag.value = `#${mergeTag.name}`;
        });
      } catch (err) {
        console.log(err);
      }
    };

    self._getPropertyAiConfig = function (propertyId) {
      conversationsService
        .getPropertyAiConfig(propertyId)
        .then(({ data }) => {
          if (data.ai_config_data.results[0]) {
            $scope.data.disableLiveChat =
              !!data.ai_config_data.results[0].is_chat_enabled;
            if (!$scope.data.isCallIntelEnabled) {
              $scope.data.isTranscribed =
                !!data.ai_config_data.results[0].is_transcribed;
            }
          }
        })
        .catch((err) => {
          $scope.data.disableLiveChat = false;
          if (!$scope.data.isCallIntelEnabled) {
            $scope.data.isTranscribed = false;
          }
        })
        .finally(() => {
          if (!$scope.data.isCallIntelEnabled) {
            $rootScope.$broadcast(
              'getTranscribedStatus',
              $scope.data.isTranscribed
            );
          }
        });
      if ($scope.data.isCallIntelEnabled) {
        conversationsService
          .getPropertyCallIntel(propertyId)
          .then(({ data }) => {
            $scope.data.isTranscribed = !!data.call_intel_config.is_transcribed;
          })
          .catch((e) => {
            console.error('Error getting property call intel:', e);
            $scope.data.isTranscribed = false;
          })
          .finally(() => {
            $rootScope.$broadcast(
              'getTranscribedStatus',
              $scope.data.isTranscribed
            );
          });
      }
    };

    self._getClientCallStatus = function () {
      voiceService.isClientOnACall().then((data) => {
        $scope.data.onInboundCall = data;
      });
    };
    self._getTeamMembers = () => {
      return appDataService.getTeamMembers().map((member) => {
        return {
          id: member.Manager.id,
          name: `${member.ManagerInfo.first_name} ${member.ManagerInfo.last_name}`,
          email: member.ManagerInfo.email,
          photo: member.ManagerInfo.photo
        };
      });
    };
    self._reset = function () {
      $scope.data.nbaTooltip = {
        class: '',
        text: ''
      };
      $scope.data.activeCall = voiceService.getActiveCallForStream(
        $scope.stream.id
      );
      $scope.data.isRenterMailer =
        $scope.prospect &&
        conversationsService.isRenterMailer($scope.prospect.profile.email);
      $scope.data.canSendChat = find(
        $scope.stream.messages,
        function (message) {
          return (
            message.sender_type !== 'manager' && message.source === 'doorway'
          );
        }
      );

      var lastTarget = conversationsService.getLastMessageTarget($scope.stream);
      const teamMembers = self._getTeamMembers();

      $scope.ownerInfo =
        person &&
        teamMembers.find((member) => {
          if ($scope.prospect) {
            return member.id === person?.assigned_manager_id;
          } else {
            return member.id === person?.added_by;
          }
        });

      if (
        $scope.isAgentAttributionEnable &&
        $scope.ownerInfo.id !== $scope.data.user
      ) {
        self._getManagerEmailTags($scope.ownerInfo.id);
      }
      $scope.data.messageModeIndex = findIndex(
        $scope.data.tabIndices,
        function (mode) {
          return mode.target === lastTarget;
        }
      );

      $scope.data.showFormatting =
        conversationsService.getToggleFormatting('open-formatting');

      if ($scope.data.showFormatting === null) {
        $scope.toggleFormatting();
      }

      var userTypeAndId = self._getOtherUserTypeAndId();
      $scope.messageText = conversationsService.getDraft(
        userTypeAndId.userType,
        userTypeAndId.userId,
        $scope.getMessageMode().target
      );

      self._generateHasEmailOptOut();
    };

    $rootScope.$on('TransferGuestCard', function (event, newManagerId) {
      const teamMembers = self._getTeamMembers();
      const leadUser = $scope.prospect || $scope.resident;
      $scope.ownerInfo =
        leadUser && teamMembers.find((member) => member.id === newManagerId);
      if (
        $scope.isAgentAttributionEnable &&
        $scope.ownerInfo.id !== $scope.data.user
      ) {
        self._getManagerEmailTags($scope.ownerInfo.id, true);
      }
    });

    $scope.$on('$mdMenuClose', function (event, context) {
      // When the available units menu closes, reset the property ID from the binder so if the user opens it from the footer button, it behaves
      // like it always did
      if (
        context &&
        context.length > 0 &&
        context.attr('data-menu-id') === 'availableUnits'
      ) {
        $scope.data.propertyIdFromBinder = null;
      }
    });
    $scope.getPropertyUnitListings = function (propertyId) {
      return propertyApi.getPropertyUnitListings(propertyId);
    };
    $scope.openLeasingBinder = function () {
      $scope.isLeasingBinderOpen = true;
      $scope.defaultPropertyId = undefined;
    };

    $scope.markAsLostLeasingBinder = false;
    $scope.defaultPropertyId = undefined;
    $rootScope.$on('markAsLostLeasingBinderOpen', (_, params) => {
      $scope.isLeasingBinderOpen = true;
      $scope.markAsLostLeasingBinder = true;
      $scope.defaultPropertyId = params.property;
      $scope.$apply();
    });

    $scope.closeLeasingBinder = function () {
      $scope.isLeasingBinderOpen = false;
      $scope.markAsLostLeasingBinder = false;
      $scope.$apply();
    };
    $scope.getFloorPlans = function () {
      if ($scope.data.useNewUnitsFeature) {
        return unitApi.getLayouts($scope.prospect.property_id);
      } else {
        return propertyApi.getPropertyFloorplans($scope.prospect.property_id);
      }
    };
    $scope.saveWalkinDetails = function (walkInData) {
      $scope.data.walkinDataSaving = true;
      if (
        $scope.data.property.Property.preferences.require_prospect_floorplan
      ) {
        if ($scope.data.useNewUnitsFeature) {
          $scope.prospect.preferences.preferred_layout_id =
            walkInData.selectedFloorPlan;
        } else {
          $scope.prospect.preferences.preferred_property_floorplan_id =
            walkInData.selectedFloorPlan;
        }

        prospectsApi
          .updateProspect($scope.prospect.id, {
            preferences: $scope.prospect.preferences
          })
          .then(
            function () {
              self._createVisit(walkInData);
            },
            function () {
              $mdToast.showSimple('Error adding visit.');
              $scope.data.walkinDataSaving = false;
              $scope.state.view = $scope.viewStates.visit;
            }
          );
      } else {
        self._createVisit(walkInData);
      }
    };
    self._createVisit = function (walkInData) {
      const visitTime = `${walkInData.walkinDate}T${moment(
        walkInData.walkinTime
      ).format('HH:mmZ')}`;

      visitsService
        .createVisit(
          $scope.prospect.id,
          visitTime,
          null,
          walkInData.selectedUser
        )
        .then(
          function (response) {
            $scope.data.walkinDataSaving = false;
            $scope.data.showWalkinModal = false;
            shownUnitsModalService
              .openShownUnitsModal($scope.prospect, response.data.visit)
              .then(function (shownUnits) {
                visitsService.onShownUnitsAdded(
                  response.data.visit,
                  shownUnits
                );
                return true;
              });
          },
          function () {
            $mdToast.showSimple('Error adding visit.');
            $scope.data.walkinDataSaving = false;
            return false;
          }
        );
    };
    $scope.generateTimeOptions = function (date) {
      /**
       * Format selected date in mm/dd/yyyy
       */
      const selectedFormattedDate = timeService.get(date).format('MM/DD/YYYY');
      /**
       * Format current date into similar format as our selected date.
       */
      const currentFormattedDate = timeService.get().format('MM/DD/YYYY');
      let timeSlots = [];
      let timeWalker = timeService.get().startOf('day').hour(7);
      /**
       * If our current date is the same as the selected date, then we cut off time selection at our current time.
       */
      const todayEnd =
        selectedFormattedDate === currentFormattedDate
          ? timeService.get()
          : timeService.get().startOf('day').hour(22);
      while (timeWalker.isBefore(todayEnd)) {
        timeSlots.push({
          label: timeService.clone(timeWalker).format('h:mm a'),
          value: timeService.clone(timeWalker)
        });
        timeWalker.add(5, 'minutes');
      }

      return timeSlots;
    };
    $scope.getPropertiesForBinder = async function () {
      const nearbyPropertiesRequest = propertyApi.getNearbyProperties(
        $scope.prospect.property_id,
        true,
        true,
        true
      );
      const communitiesRequest = managerApi.getMyCommunities(true, true, true);
      const responses = await Promise.all([
        nearbyPropertiesRequest,
        communitiesRequest
      ]);

      const communities = (responses[0].data.properties || []).map(
        (property) => {
          if (property.community_data) {
            return property.community_data;
          } else {
            const newProperty = getEmptyProperty();
            newProperty.property_id = property.id;
            newProperty.location.name = property.name.trim();

            return newProperty;
          }
        }
      );

      if (
        responses[1].data.communities.find(
          (item) =>
            item.property_id === $scope.data.property.Property.data.property_id
        )
      ) {
        responses[0].data.property_forwarding_numbers[
          $scope.data.property.Property.data.property_id
        ] =
          responses[1].data.property_forwarding_numbers[
            $scope.data.property.Property.data.property_id
          ];
        responses[0].data.property_office_hours[
          $scope.data.property.Property.data.property_id
        ] =
          responses[1].data.property_office_hours[
            $scope.data.property.Property.data.property_id
          ];
        responses[0].data.property_touring_hours[
          $scope.data.property.Property.data.property_id
        ] =
          responses[1].data.property_touring_hours[
            $scope.data.property.Property.data.property_id
          ];
        responses[0].data.property_custom_fees[
          $scope.data.property.Property.data.property_id
        ] =
          responses[1].data.property_custom_fees[
            $scope.data.property.Property.data.property_id
          ];

        communities.push($scope.data.currentCommunity);
      }

      // This data structure matches what the leasing binder drawer expects, and is also what the /communities endpoint returns
      return {
        data: {
          communities,
          property_forwarding_numbers:
            responses[0].data.property_forwarding_numbers,
          property_office_hours: responses[0].data.property_office_hours,
          property_touring_hours: responses[0].data.property_touring_hours,
          property_custom_fees: responses[0].data.property_custom_fees
        }
      };
    };

    // Invokes the availability drawer from the leasing binder
    $scope.showAvailableUnits = function (property) {
      if (!$scope.isAvailabilityDrawerEnable) {
        $scope.data.propertyIdFromBinder = property.property_id;
        const availableUnitsButton = $('#AvailableUnitsButton');

        if (availableUnitsButton.length > 0) {
          availableUnitsButton.trigger('click');
        }
      } else {
        $scope.availabilityDrawerProperty = property;
        $scope.showAvailabilityDrawer = true;
        $scope.showAvailabilityBackButton = true;
      }

      $scope.$apply();
    };

    $scope.connectedProfiles = [];

    $rootScope.$on('prospect-connected-profiles', (_, connectedProfiles) => {
      $scope.connectedProfiles = connectedProfiles;
    });

    $scope.attachQuoteToMessage = function (data) {
      if ($scope.stream.id === data.stream_id) {
        let setQuoteTarget = false;
        if (data.attachment && $scope.getMessageMode().target === 'call') {
          setQuoteTarget = $scope.handleQuoteAttachmentDuringCallTab(
            data,
            true
          );
        }
        if ($scope.getMessageMode().target === 'email' && !setQuoteTarget) {
          $scope.attachments.push(data.attachment);
        } else if (!setQuoteTarget) {
          $scope.openAttachmentDialog([data.attachment]);
        }
        $scope.apply();
      }
    };

    $scope.isMissingPhone = function () {
      var person = $scope.prospect || $scope.resident;
      return !(person && person.profile.phone);
    };

    $scope.isInvalidPhone = function () {
      var person = $scope.prospect || $scope.resident;
      return (
        person && person.profile.phone && !person.profile.phone.can_receive_call
      );
    };

    $scope.isMissingSMSConsent = function () {
      var user = $scope.prospect || $scope.resident;

      return (
        !$scope.isMissingPhone() &&
        user &&
        (!user.sms_consent || user.sms_consent.status !== 'granted')
      );
    };

    $scope.hasConsentOverride = function () {
      return (
        $scope.prospect &&
        $scope.prospect.sms_consent &&
        $scope.prospect.sms_consent.has_express_consent_override &&
        $scope.data.hasReceivedSMS &&
        self._prospectBeforeConsentCutoff() &&
        $scope.prospect.sms_consent.status !== 'revoked' &&
        $scope.prospect.sms_consent.status !== 'declined'
      );
    };

    $scope.isSMSOptInDisabled = () =>
      !$scope.isMissingSMSConsent() || $scope.hasConsentOverride();

    $scope.isMissingEmail = function () {
      var person = $scope.prospect || $scope.resident;
      return !(person && person.profile.email) || $scope.isRenterMailer;
    };

    $scope.isMissingFacebookMessenger = function () {
      var person = $scope.prospect || $scope.resident;
      return !(person && person.has_facebook_messenger);
    };

    $scope.getMessageMode = function () {
      return $scope.data.tabIndices[$scope.data.messageModeIndex];
    };

    $scope.shouldShowSMSConsent = function () {
      if (self._lastMessageCount !== $scope.stream.messages.length) {
        $scope.data.hasReceivedSMS = some(
          $scope.stream.messages,
          function (message) {
            return (
              message.source === 'sms' && message.sender_type === 'prospect'
            );
          }
        );

        self._lastMessageCount = $scope.stream.messages.length;
      }

      return (
        $scope.prospect &&
        $scope.prospect.sms_consent &&
        $scope.prospect.sms_consent.has_express_consent_override &&
        $scope.prospect.sms_consent.status !== 'granted' &&
        $scope.data.hasReceivedSMS &&
        self._prospectBeforeConsentCutoff() &&
        $scope.getMessageMode().target === 'sms'
      );
    };

    self._prospectBeforeConsentCutoff = function () {
      return self._prospectCreatedMoment.isBefore(self._consentCutoffMoment);
    };

    $scope.markStreamAsUnread = function () {
      conversationsService
        .markAsUnreadByIds(
          [$scope.stream.id],
          $scope.stream.type,
          $scope.manager.id
        )
        .success(function () {
          $scope.stream.isUnread = true;
          streamCarouselService.hideCarousel();
          $location.url('/inbox/' + $scope.manager.id);
        });
    };
    $scope.closeWalkinModal = function () {
      $scope.$apply(() => {
        $scope.data.showWalkinModal = false;
      });
    };
    $scope.addVisit = function () {
      $scope.data.showWalkinModal = true;
    };

    $scope.addTour = function () {
      if (!$scope.prospect) {
        return;
      }

      return prospectAppointmentModalFactory.openAddProspectAppointmentModal(
        $scope.prospect.id
      );
    };

    $scope.attachBrochure = function (propertyId) {
      if (!propertyId) {
        return;
      } else {
        const prospectId = $scope.prospect.id;
        if ($scope.getMessageMode().target !== 'email') {
          $scope.tabChange('email');
          $scope.data.messageModeIndex = 2;
        }
        propertyApi
          .addLeasingBrouchre(propertyId, prospectId)
          .then(function (response) {
            const url = response.data.pdf_url || undefined;
            const urlParts = url ? url.split('/') : [];
            const filename =
              urlParts.length > 0
                ? urlParts[urlParts.length - 1]
                : 'Leasing Brochure';
            $scope.attachments.push({
              url,
              filename,
              mimetype: 'application/pdf',
              type: 'pdf',
              isLeasingBrochure: true
            });
          })
          .catch((err) => {
            console.log('error', err);
          });
      }
    };

    $scope.addNote = function () {
      $scope.showNoteModal = true;
    };

    $scope.addReminder = function () {
      if ($scope.prospect) {
        if (!$scope.prospect.enable_cheatproof_engagement_score) {
          return self._showFollowUpModal($scope.prospect);
        }

        // Cheat-proof handling
        conversationsService
          .getProspectEngagementSettings($scope.prospect.id)
          .then(function (response) {
            $scope.prospect.enable_cheatproof_engagement_score =
              response.data.engagement_settings.enable_cheatproof_engagement_score;
            $scope.prospect.disable_follow_ups =
              response.data.engagement_settings.disable_follow_ups;

            if (
              $scope.prospect.enable_cheatproof_engagement_score &&
              $scope.prospect.disable_follow_ups
            ) {
              var disableFollowUpNote = $mdDialog.alert({
                title: 'Adding Follow-up Requires Communication',
                content:
                  'You are not able to add an additional follow-up until you have contacted the prospect.',
                ok: 'Got it'
              });

              return $mdDialog.show(disableFollowUpNote);
            } else {
              return self._showFollowUpModal($scope.prospect);
            }
          });
      } else if ($scope.resident) {
        conversationsService
          .getResidentEngagementSettings($scope.resident.id)
          .then((response) => {
            $scope.resident.enable_cheatproof_resident_engagement_score =
              response.data.engagement_settings.enable_cheatproof_resident_engagement_score;
            $scope.resident.disable_follow_ups =
              response.data.engagement_settings.disable_follow_ups;

            if (
              $scope.resident.enable_cheatproof_resident_engagement_score &&
              $scope.resident.disable_follow_ups
            ) {
              const disableFollowUpNote = $mdDialog.alert({
                title: 'Adding Follow-up Requires Communication',
                content:
                  'You must first communicate with this resident in Knock before adding a future followup.',
                ok: 'Got it'
              });

              return $mdDialog.show(disableFollowUpNote);
            } else {
              return self._showFollowUpModal($scope.resident);
            }
          });
      }
    };

    $scope.addActivity = function (activityPayload) {
      const userData = $scope.prospect || $scope.resident;

      return userInteractionsService.addNewActivity(
        userData,
        activityPayload.type,
        activityPayload.message,
        activityPayload.reminder_time
      );
    };

    self._showFollowUpModal = function (person) {
      $scope.followUpData = [
        {
          id: person.id,
          name: person.profile.first_name + ' ' + person.profile.last_name,
          email: '',
          streamId: ''
        }
      ];
      $scope.showFollowUpModal = true;
    };

    $scope.closeFollowUpModal = function (refetch) {
      $scope.$apply(() => {
        $scope.showFollowUpModal = false;
        $scope.followUpData = [];

        if (refetch) {
          $rootScope.$emit('reloadProspectDetails');
        }
      });
    };

    $scope.closeNoteModal = function () {
      $scope.$apply(() => {
        $scope.showNoteModal = false;
      });
    };

    $scope.addResidentPackage = function () {
      if (!$scope.resident) {
        return;
      }

      return userInteractionsResidentPackageModalService.openModal(
        $scope.resident
      );
    };

    $scope.initiateCall = function () {
      if (
        !($scope.prospect || $scope.resident) ||
        $scope.data.isPhoneCallTransient ||
        $scope.data.isBrowserCallInTransient
      ) {
        return;
      }

      $scope.data.isPhoneCallTransient = true;
      var person = $scope.prospect || $scope.resident;
      var personType = $scope.prospect ? 'prospect' : 'resident';
      var source = $scope.prospect ? $scope.prospect.source : null;

      voiceService
        .placeCall(personType, person.id, $scope.stream.id, source)
        .then(function (activeCall) {
          $scope.data.activeCall = activeCall;
          $scope.data.isPhoneCallTransient = false;
          $scope._isCallInTransient = true;
        })
        .catch((err) => {
          $scope.data.isPhoneCallTransient = false;
          $scope._isCallInTransient = false;
        });
    };

    $scope.initiateCallViaVoiceApp = function () {
      if (
        !($scope.prospect || $scope.resident) ||
        $scope.data.isBrowserCallInTransient ||
        $scope.data.isPhoneCallTransient
      ) {
        return;
      }

      if ($rootScope.featureFlags.LMA_VOICEAPP_STATUS_ENHANCEMENT) {
        const status = $rootScope.voiceAppStatus;
        windowManagementService.openKnockVoice($scope.stream.id, status);
      } else {
        windowManagementService.openKnockVoice($scope.stream.id, undefined);
      }
      $scope.data.isBrowserCallTransient = true;
      $scope.data.isVoiceAppCall = true;
    };

    $scope.initiateCallViaBrowser = function () {
      if (
        !($scope.prospect || $scope.resident) ||
        $scope.data.isBrowserCallInTransient ||
        $scope.data.isPhoneCallTransient
      ) {
        return;
      }

      var person = $scope.prospect || $scope.resident;
      var personType = $scope.prospect ? 'prospect' : 'resident';
      var source = $scope.prospect ? $scope.prospect.source : null;

      var _attachActiveCall = function (activeCall) {
        $scope.data.activeCall = activeCall;
      };
      const _setBrowserCallTransient = function (callingProgress) {
        $scope.data.isBrowserCallInTransient = callingProgress;
        $scope._isCallInTransient = true;
      };

      var personDisplayName =
        person.profile.first_name + ' ' + person.profile.last_name;
      voiceService.placeCallViaBrowser(
        personType,
        personDisplayName,
        $scope.stream.id,
        source,
        _attachActiveCall,
        _setBrowserCallTransient
      );
    };

    $scope.endCall = function () {
      if ($scope.data.isVoiceAppCall) {
        $scope.data.isBrowserCallTransient = false;
        $scope.data.isVoiceAppCall = false;
        $scope._isCallInTransient = false;
      } else {
        if (
          !$scope.data.activeCall.isActive ||
          isEmpty($scope.data.activeCall)
        ) {
          return;
        }

        voiceService.endCall($scope.data.activeCall.callId);
        $scope.data.activeCall = null;
        $scope.data.isPhoneCallTransient = false;
        $scope.data.isBrowserCallInTransient = false;
        $scope._isCallInTransient = false;
      }
    };

    $scope.toggleFormatting = function () {
      $scope.data.showFormatting = !$scope.data.showFormatting;
      $scope.textControlsDisplay();
    };

    $scope.shareSMSOptIn = function () {
      $scope.data.messageModeIndex = 1;

      const { prospect, resident, stream } = $scope;
      const isResident = stream.type === 'resident';
      const recipientId = isResident ? resident.id : prospect.id;

      shareSMSOptInModalFactory
        .openShareSMSOptIn(isResident, recipientId)
        .then(function (consentUrl) {
          $scope.appendText(
            'To allow us to send text messages, follow this link: ' + consentUrl
          );

          // Fill in the subject line with a more informative one
          const property = $scope.data.property;

          if (
            property &&
            property.Property &&
            property.Property.data &&
            property.Property.data.location &&
            property.Property.data.location.name
          ) {
            const name = property.Property.data.location.name;
            $scope.data.subject.editedText =
              'SMS Communication Opt-in for ' + name;
            $scope.saveSubject();
          } else {
            $scope.data.subject.editedText = 'SMS Communication Opt-in';
            $scope.saveSubject();
          }
        });
    };

    $scope.updateMessage = (message) => {
      $scope.$apply(() => {
        $scope.messageText = message;
      });
    };

    $scope.token = $auth.getToken();

    $scope.isTextSizeExceeds = function () {
      if ($scope.messageText) {
        let stringContent = $scope.messageText.trim();
        stringContent = stringContent.replace(/&[a-zA-Z]+;|<[^>]*>/g, '');
        return stringContent.length > $scope.getMessageMode().maxLength;
      } else {
        return false;
      }
    };

    $scope.isSendDisabled = function () {
      var messageMode = $scope.getMessageMode();

      return (
        $scope.needsToCheckConsentBox() ||
        (messageMode.target === 'email' && !$scope.messageSubject) ||
        $scope.isTextSizeExceeds() ||
        ($scope.isMissingPhone() &&
          $scope.isMissingEmail() &&
          !($scope.userIsOnline || $scope.data.userWasOnline) &&
          $scope.isMissingFacebookMessenger())
      );
    };

    $scope.needsToCheckConsentBox = function () {
      return (
        $scope.prospect &&
        $scope.getMessageMode().target === 'sms' &&
        $scope.prospect.sms_consent &&
        $scope.prospect.sms_consent.status !== 'granted' &&
        !$scope.prospect.consentOverriden
      );
    };

    $scope.isEmailAvailable = function () {
      return $scope.prospect && $scope.prospect.profile.email;
    };

    $scope.textControlsDisplay = function () {
      conversationsService.storeToggleFormatting($scope.data.showFormatting);
    };

    $scope.insertApplicationUrl = function () {
      let urlToAdd = '';

      const applicationUrlMergeTag = $scope.mergeTags.find(
        (mergeTag) => mergeTag.name === 'ApplicationURL'
      );
      if (applicationUrlMergeTag && applicationUrlMergeTag.value) {
        urlToAdd = applicationUrlMergeTag.value;
      }

      if (urlToAdd) {
        $scope.$apply(() => {
          if ($scope.messageText) {
            $scope.messageText += urlToAdd;
          } else {
            $scope.messageText = urlToAdd;
          }
        });
      }
    };

    $scope.openAttachmentDialog = function (attachments) {
      addAttachmentDialogFactory
        .openAddAttachmentDialog(
          $scope.stream,
          $scope.prospect,
          $scope.messageText,
          $scope.getMessageMode().target,
          null,
          attachments
        )
        .then(function (attachments) {
          if ($scope.getMessageMode().target !== 'email') {
            $scope.messageText = '';
            return;
          }

          $scope.attachments = ($scope.attachments || []).concat(attachments);
        });
    };

    $scope.removeAttachment = function (attachmentToRemove) {
      remove($scope.attachments, function (remoteAttachment) {
        if (remoteAttachment.isLeasingBrochure) {
          return remoteAttachment.filename === attachmentToRemove.filename;
        } else {
          return (
            remoteAttachment.delete_token === attachmentToRemove.delete_token
          );
        }
      });
    };

    $scope.sendMessage = function () {
      // prevent the user from sending a rich text email
      // that contains one or more drag and drop attachments,
      // but no actual text
      const targetType =
        $scope.getMessageMode().target === 'sms' ? 'SMS' : 'Email';

      if ($scope.enableRichTextEditor) {
        let text = $scope.messageText || '';
        text = text.replace(/(<([^>]+)>|&nbsp;)/g, '').trim();
        if (text.length === 0) {
          $mdToast.showSimple(`${targetType} must contain text`);
          return;
        }
      }
      if (!$scope.messageText) {
        $mdToast.showSimple(`${targetType} must contain text`);
        return;
      }

      if ($scope.prospect) {
        $scope.prospect.consentOverriden = false;
      }

      var emailRecipients;

      if ($scope.ccRecipients.length > 0) {
        emailRecipients = [$scope.targetEmailAddress];

        $scope.ccRecipients.forEach((ccRecipient) => {
          if (ccRecipient.isValid) {
            emailRecipients.push(ccRecipient.emailAddress);
          }
        });
      }

      $scope.onSendMessage($scope.getMessageMode().target, emailRecipients);
    };

    $scope.setSendAsUserId = function (userId, applyTrigger = false) {
      if ($scope.isAgentAttributionEnable && $scope.ownerInfo.id === userId) {
        self._getManagerEmailTags(userId);
      } else {
        self._getMergeTags();
      }
      const data = {
        userId,
        applyTrigger
      };
      $rootScope.$emit('setSendAsManagerId', data);
    };

    $scope.appendText = function (text, fromQuickReply) {
      $scope.messageText = $scope.messageText || '';

      if (!fromQuickReply) {
        // Swap angled brackets for square brackets. Otherwise, anything rendered inside the angled brackets will disappear when pushed into
        // Angular's text escaper.
        //
        // TODO: This logic can be removed once the enhanced quick replies feature is enabled for all users.
        let replacedText = text.replace(/</g, '[');
        replacedText = replacedText.replace(/>/g, ']');

        // Create escaped text with angular. This will
        // cleanly handle any special characters
        let escapedText = null;
        if ($scope.messageText && !endsWith($scope.messageText, ' ')) {
          escapedText = angular.element(`<div> ${replacedText} </div>`).text();
        } else {
          escapedText = angular.element(`<div>${replacedText} </div>`).text();
        }

        if ($scope.getMessageMode().target === 'email') {
          // swap newlines for <br/> for email
          escapedText = escapedText.replace(/(?:\r\n|\r|\n)/g, '<br />');
        }

        text = escapedText;
      }

      // Resolve merge tags in appended text
      $scope.mergeTags.forEach((mergeTag) => {
        text = text.replaceAll(`#${mergeTag.name}`, mergeTag.value);
      });

      if (fromQuickReply) {
        $scope.$apply(() => {
          $scope.messageText += text;
        });
      } else {
        $scope.messageText += text;
      }
    };

    self._initDragDropHandlers = function () {
      var _onDragOver = function (event) {
        event.stopPropagation();
        event.preventDefault();

        $scope.$apply(function () {
          $scope.dropFieldClass = 'drag-over';
        });
      };

      var _onDragLeave = function (event) {
        event.stopPropagation();
        event.preventDefault();

        $scope.$apply(function () {
          $scope.dropFieldClass = 'drag-leave';
        });
      };

      var _onDragDrop = function (event) {
        event.stopPropagation();
        event.preventDefault();

        $scope.$apply(function () {
          $scope.handleDroppedFiles(event.dataTransfer);
          $scope.dropFieldClass = 'drag-leave';
        });
      };

      var fileSelect = document.getElementById('chatTextArea');

      fileSelect.addEventListener('dragover', _onDragOver, false);
      fileSelect.addEventListener('dragleave', _onDragLeave, false);
      fileSelect.addEventListener('drop', _onDragDrop, false);
    };

    $scope.handleDroppedFiles = function (element) {
      if (element.files.length === 0) {
        return;
      }

      var attachments = map(element.files, function (file) {
        file.isLocal = true;
        file.mimetype = file.type;
        file.url = $window.URL.createObjectURL(file);

        return file;
      });

      addAttachmentDialogFactory
        .openAddAttachmentDialog(
          $scope.stream,
          $scope.prospect,
          $scope.messageText,
          $scope.getMessageMode().target,
          attachments
        )
        .then(function () {
          $scope.messageText = '';
        });
    };

    $scope.cancelReplyingToMessage = function () {
      $scope.onSetReplyingTo(null);
    };

    $scope.startEditSubject = function () {
      $scope.cancelReplyingToMessage();

      $scope.data.subject.isEditing = true;
      $scope.data.subject.editedText = $scope.replyingToMessage
        ? $scope.replyingToMessage.subject
        : $scope.messageSubject;

      var input = angular.element('.subject-input');

      $timeout(
        function () {
          input.focus();
          input.select();
        },
        1,
        true
      );
    };

    $scope.saveSubject = function () {
      $scope.data.subject.isEditing = false;
      $scope.messageSubject = $scope.data.subject.editedText;
    };

    $scope.startTyping = function () {
      if (!$scope.data.isTyping) {
        $scope.data.isTyping = true;
        conversationsService.sendStartTyping();
      } else {
        $timeout.cancel(self._stopTypingTimeout);
      }

      self._stopTypingTimeout = $timeout(function () {
        $scope.data.isTyping = false;

        conversationsService.sendStopTyping();
      }, 1000);
    };

    $scope.tabChange = function (target, attachment = null) {
      if (target !== 'email') {
        $scope.cancelReplyingToMessage();
      }
      if (
        $scope.data.nbaTooltip?.text !== $scope.messageModes[target].nbaTooltip
      ) {
        $scope.data.nbaTooltip = {
          class: '',
          text: ''
        };
      }

      var isManager = $scope.prospect || $scope.resident;

      if (isManager) {
        var userTypeAndId = self._getOtherUserTypeAndId();
        $scope.messageText = conversationsService.getDraft(
          userTypeAndId.userType,
          userTypeAndId.userId,
          target
        );
      }
      if (attachment) {
        $scope.attachments.push(attachment);
        $scope.isQuoteAttachmentAppend = true;
      } else {
        if (!$scope.isQuoteAttachmentAppend) {
          $scope.attachments = [];
        }
        $scope.isQuoteAttachmentAppend = false;
      }
      if (target === 'email') {
        var lastRenterEmailMessage = findLast(
          $scope.stream.messages,
          function (message) {
            return (
              message.sender_type !== 'manager' &&
              message.type !== 'notification' &&
              (message.source === 'email' ||
                message.source === 'mailer' ||
                message.source === 'ai')
            );
          }
        );

        if (lastRenterEmailMessage) {
          $scope.onSetReplyingTo(lastRenterEmailMessage);
        } else {
          $scope.data.subject.editedText = $scope.stream.subject;
          $scope.saveSubject();
        }
      }
    };

    $scope.shouldShowProspectSelfVerifyIdUrl = function () {
      return (
        $scope.prospect &&
        ($rootScope.appPreferences.company.enable_mobile_tours ||
          $scope.data.property.Property.preferences.enable_selfie_scan)
      );
    };

    $scope.shareSelfVerifyIdUrl = function () {
      $scope.data.isGettingSelfVerifyIdUrl = true;
      prospectsApi
        .getProspectSelfVerifyIdUrl($scope.prospect.id)
        .then((response) => {
          const { self_verify_id_url } = response.data || {};
          if (!self_verify_id_url) {
            throw new Error('Missing self-verify id url');
          }

          $scope.appendText(
            'To verify your ID please follow this link: ' + self_verify_id_url
          );
          // LMA_PLAID_APIV2_CHECK" flag is enabled, the link will not have an expiration time.
          // This flag is used to adjust the handling of the related text accordingly.

          if ($scope.isPlaidApiv2Checked) {
            $scope.appendText(
              '\nThe link you received will expire in 1 hour. Please make sure to complete your scan before this time to avoid any inconvenience.'
            );
          }
        })
        .catch(() => {
          $mdToast.showSimple('Error getting Self-Verify ID URL');
        })
        .finally(() => {
          $scope.data.isGettingSelfVerifyIdUrl = false;
        });
    };

    self._setOverflowMenuItems = function () {
      var messageMode = $scope.getMessageMode().target;
      var shouldShowProspectSelfVerifyIdUrl =
        $scope.shouldShowProspectSelfVerifyIdUrl();

      if (messageMode === 'sms') {
        $scope.overflowMenuItems = [
          {
            label: 'Self-Verify ID',
            value: 0,
            icon: 'PersonSecure',
            disabled: false,
            visible:
              shouldShowProspectSelfVerifyIdUrl &&
              $window.innerWidth < styles.smsOverflowMenuTier1Width
          },
          {
            label: 'Attachments',
            value: 2,
            icon: 'Attachment',
            disabled: false,
            visible: $window.innerWidth < styles.smsOverflowMenuTier2Width
          },
          {
            label: 'Emojis',
            value: 3,
            icon: 'Emoji',
            disabled: false,
            visible: $window.innerWidth < styles.smsOverflowMenuTier3Width
          }
        ];
      } else {
        $scope.overflowMenuItems = [
          {
            label: 'Self-Verify ID',
            value: 0,
            icon: 'PersonSecure',
            disabled: false,
            visible:
              shouldShowProspectSelfVerifyIdUrl &&
              $window.innerWidth < styles.emailOverflowMenuTier1Width
          },
          {
            label: 'SMS Opt In',
            value: 1,
            icon: 'PhoneAction',
            disabled: $scope.isSMSOptInDisabled(),
            visible:
              !$scope.isMissingEmail() &&
              $window.innerWidth < styles.emailOverflowMenuTier2Width
          },
          {
            label: 'Attachments',
            value: 2,
            icon: 'Attachment',
            disabled: false,
            visible: $window.innerWidth < styles.emailOverflowMenuTier3Width
          }
        ];
      }

      var widthForEmailMenu = styles.emailOverflowMenuTier1Width;
      var widthForSMSMenu = styles.smsOverflowMenuTier1Width;

      if (!shouldShowProspectSelfVerifyIdUrl) {
        widthForEmailMenu = styles.emailOverflowMenuTier2Width;
        widthForSMSMenu = styles.smsOverflowMenuTier2Width;
      }

      $scope.showOverflowMenu =
        ($window.innerWidth < widthForEmailMenu && messageMode === 'email') ||
        ($window.innerWidth < widthForSMSMenu && messageMode === 'sms');
    };

    self._setOverflowMenuItems();

    $scope.openOverflowMenuItem = function (menuItem) {
      switch (menuItem.value) {
        case 0:
          $scope.shareSelfVerifyIdUrl();
          break;
        case 1:
          $scope.shareSMSOptIn();
          break;
        case 2:
          $scope.openAttachmentDialog();
          break;
        case 3:
          var emojiPickerHeight = 250;
          var textarea = $('#chatTextArea');
          var overflowMenuContainer = $('.overflow-menu-container');
          textarea.emojiPicker('toggle');

          // We invoke the emoji picker window and position it relative to the button that displays the overflow dots menu
          $('.emojiPicker')
            .css({
              left: overflowMenuContainer.offset().left.toString() + 'px',
              top:
                (
                  overflowMenuContainer.offset().top - emojiPickerHeight
                ).toString() + 'px',
              zIndex: 99999999
            })
            .on('click', function () {
              if (typeof textarea[1] !== 'undefined') {
                $scope.messageText = textarea[1].value;
              } else {
                if (typeof $scope.message !== 'undefined') {
                  $scope.message.text = textarea[0].value;
                } else {
                  $scope.messageText = textarea[0].value;

                  if (typeof $scope.data.messageText !== 'undefined') {
                    $scope.data.messageText = textarea[0].value;
                  }
                }
              }

              $scope.$apply();
            });

          break;
        default:
      }
    };

    $scope.setEmailSubject = function (subject) {
      $scope.data.subject.editedText = subject;
      $scope.saveSubject();
      $scope.$apply();
    };

    $scope.setRecipients = function (ccRecipients) {
      $scope.ccRecipients = ccRecipients;
      $scope.$apply();
    };

    $scope.generateQuote = function (unitListing) {
      quotesService.createQuote(
        unitListing,
        $scope.prospect,
        $scope.data.unitListings
      );
    };

    // Invokes the availability drawer from the Email tab in the footer
    $scope.availabilityDrawerAction = function () {
      $scope.availabilityDrawerProperty = $scope.data.property.Property.data;

      // let propertyId = '';
      //
      // if ($scope.prospect && $scope.prospect.property_id) {
      //   propertyId = $scope.prospect.property_id;
      // } else if ($scope.resident && $scope.resident.residence?.property_id) {
      //   propertyId = $scope.resident.residence.property_id;
      // } else {
      //   propertyId = $scope.data.properties[0].Property.id;
      // }
      //
      // $scope.availabilityDrawerPropertyId = propertyId;

      $scope.showAvailabilityDrawer = true;
    };

    $scope.closeAvailabilityDrawer = function (isBackButtonClicked = true) {
      $scope.showAvailabilityDrawer = false;
      $scope.showAvailabilityBackButton = false;

      if (!isBackButtonClicked) {
        $scope.isLeasingBinderOpen = false;
      }

      $scope.$apply();
    };

    const _getDeposit = function (propertyId) {
      const property = find($scope.data.properties, function (prop) {
        return prop.Property.id === propertyId;
      });

      let leasingData;
      if (property?.leasing) {
        leasingData = property.leasing;
      } else if (property?.Property?.data?.leasing) {
        // the new availabilities drawer uses a slightly different structure for property data
        leasingData = property.Property.data.leasing;
      }

      return leasingData?.terms?.deposit || null;
    };

    $scope.hasValidRentMatrix = function (unit) {
      const now = moment();
      if (unit && unit.rentMatrix) {
        for (const key of Object.keys(unit.rentMatrix)) {
          if (now.isSameOrBefore(key, 'day')) {
            return true;
          }
        }
      }
      return false;
    };
    $scope.isSendQuoteDisabled = function () {
      const user = $scope.prospect || $scope.resident;
      return (
        ($scope.isMissingEmail() || $scope.data.hasEmailOptOut) &&
        user.sms_consent &&
        user.sms_consent.status !== 'granted'
      );
    };
    $scope.generateQuoteLegacy = function (unit, prospectId) {
      const deposit = _getDeposit(unit.propertyId);
      const hasValidRentMatrix = $scope.hasValidRentMatrix(unit);
      const isEntrataIntegration = ($scope.prospect?.integrations || []).some(
        (integration) => integration.vendor === 'entrata'
      );

      let prospectForQuote = $scope.prospect;

      // If we are creating a quote for a recently-referred prospect, we need to use that new prospect ID
      if (prospectId) {
        prospectForQuote = cloneDeep($scope.prospect);
        prospectForQuote.id = prospectId;
      }

      if (hasValidRentMatrix) {
        rentMatrixService
          .openMatrixModal(
            unit,
            true,
            $scope.data.property.Property.id,
            isEntrataIntegration
          )
          .then(function (lease) {
            unitQuotesService.openGenerateUnitQuoteModal(
              prospectForQuote,
              unit,
              lease,
              deposit,
              $scope.isSendQuoteDisabled(),
              hasValidRentMatrix
            );
          }, {});
      } else {
        unitQuotesService.openGenerateUnitQuoteModal(
          prospectForQuote,
          unit,
          null,
          deposit,
          $scope.isSendQuoteDisabled(),
          hasValidRentMatrix
        );
      }
    };

    $scope.pasteLink = function (data, flag) {
      if (flag === 'listing') {
        self._appendShortUrl(data, 'listing');
      } else {
        const urlType =
          data.Property.type === 'multi-family' ? 'community' : 'listing';
        self._appendShortUrl(data.Property.data, urlType);
      }
    };
    self._appendShortUrl = function (propertyData, propertyType) {
      if ($scope.prospect) {
        return prospectsApi
          .getProspectsUniqueShortUrl(
            $scope.prospect.id,
            propertyType,
            propertyData.id
          )
          .then(function (response) {
            if (self._getEmailTargetType() === 'resident') {
              $scope.$apply(() => {
                $scope.appendText(response.data.short_url);
              });
            } else {
              $scope.appendText(response.data.short_url);
            }
          });
      } else {
        if (self._getEmailTargetType() === 'resident') {
          $scope.$apply(() => {
            $scope.appendText(propertyData.social.shortlink);
          });
        } else {
          $scope.appendText(propertyData.social.shortlink);
        }
      }
    };

    var appWindow = angular.element($window);

    appWindow.bind('resize', function () {
      self._setOverflowMenuItems();
    });

    self._getOtherUserTypeAndId = function () {
      var otherUser = conversationsService.getOtherUser($scope.stream);

      return {
        userId: otherUser.id,
        userType: otherUser.type
      };
    };

    self._generateHasEmailOptOut = function () {
      const stream = $scope.stream;
      if (stream) {
        const unsubscribeStatus =
          unsubscribeHelper.getUnsubscribeStatusFromStream(stream);
        if (unsubscribeStatus) {
          const { opted_out, reason } = unsubscribeStatus;
          if (reason) {
            $scope.data.unsubscribeReason = reason;
          }
          if (opted_out) {
            $scope.data.hasEmailOptOut = true;
          } else {
            $scope.data.hasEmailOptOut = false;
          }
        } else {
          $scope.data.hasEmailOptOut = false;
        }
      } else {
        $scope.data.hasEmailOptOut = false;
      }
    };

    self._getVoiceAppStatusFromPusher = function () {
      const user = userService.getUser();
      if (user) {
        const pusherClient = pusherInstanceService.getInstance();
        pusherClient.subscribe(`voice-app-user-status-${user.id}`);
        pusherClient.bind('voice-app-user-status', self._voiceAppUserStatus);
      }
    };
    self._voiceAppUserStatus = function (voiceAppStatus) {
      if (
        ($scope.data.activeCall && $scope.data.activeCall.isActive) ||
        voiceAppStatus.status === 'on_a_call'
      ) {
        $scope.data.isVoiceAppStatusInCall = true;
      }
    };

    self._initialize();
  };

  ConversationTextAreaController.$inject = [
    '$rootScope',
    '$routeParams',
    '$window',
    '$moment',
    '$scope',
    '$timeout',
    '$mdToast',
    '$mdDialog',
    '$location',
    '$auth',
    'prospectAppointmentModalFactory',
    'userInteractionsResidentPackageModalService',
    'voiceService',
    'userService',
    'addAttachmentDialogFactory',
    'shareSMSOptInModalFactory',
    'conversationsService',
    'visitsService',
    'shownUnitsModalService',
    'streamCarouselService',
    'appDataService',
    'unsubscribeHelper',
    'prospectsApi',
    'userInteractionsService',
    'propertyApi',
    'communityApi',
    'managerApi',
    'quotesService',
    'timeService',
    'unitApi',
    'windowManagementService',
    'prospectIntegrationsApi',
    'unitQuotesService',
    'rentMatrixService',
    'ProfileService',
    'messagingApi',
    'pusherInstanceService'
  ];

  app.controller(
    'ConversationTextAreaController',
    ConversationTextAreaController
  );
})(window.angular, window.jQuery);
