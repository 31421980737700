import React, { FC } from 'react';

import { ThemeProvider } from '@knockrentals/knock-shared-web';

import { Provider } from 'react-redux';
import { AngularContextProvider } from './AngularContextProvider';
import { store } from '../../../app/store';
import ViewResidentInOneSiteBody from './ViewResidentInOneSiteBody';

interface ViewResidentInOneSiteProps {
  residentId: number;
}

const ViewResidentInOneSite: FC<ViewResidentInOneSiteProps> = ({
  residentId
}) => (
  <AngularContextProvider
    context={{
      residentId
    }}
  >
    <Provider store={store}>
      <ThemeProvider>
        <ViewResidentInOneSiteBody residentId={residentId} />
      </ThemeProvider>
    </Provider>
  </AngularContextProvider>
);

export default ViewResidentInOneSite;
